import Vue from 'vue'
import axios from 'axios'
import {
  VueAxios
} from './axios'
import {
  Toast
} from 'vant';
// import {
//   ACCESS_TOKEN
// } from "@/store/mutation-types"
import store from '@/store'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    console.log(error.response.data);
    // const token = Vue.ls.get(ACCESS_TOKEN)
    const token = store.state.token
    // const token = 'qHrHd3iFO+YucOJvr5TPzwBw5XveBQg2BMrpS84lq/LLsg8qVHGvUA9ism4aVg719sGK6Th/SxK978TftZ6zxw=='
    console.log("------异常响应------", token)
    console.log("------异常响应------", error.response.status)
    console.log(data.message);
    switch (error.response.status) {
      case 403:
        Toast.fail('拒绝访问')
        break
      case 404:
        Toast.fail('很抱歉，资源未找到!')
        break
      case 500:
        if(data.message=='Token失效，请重新登录'){
          Toast.fail('登录信息失效,重新登录')
        }
        break
      case 504:
        Toast.fail('网络超时')
        break
      case 401:
        Toast.fail('未授权，请重新登录')
        // if (token) {
        //   store.dispatch('Logout').then(() => {
        //     setTimeout(() => {
        //       window.location.reload()
        //     }, 1500)
        //   })
        // }
        break
      default:
        Toast.fail(!data.message ? "系统异常，请联系管理员" : data.message)
        break
    }
  }
  return Promise.reject(error)
};

// request interceptor
service.interceptors.request.use(config => {
  const token = store.state.token
  if (token) {
    config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  if (config.method == 'get') {
    config.params = {
      _t: Date.parse(new Date()) / 1000,
      ...config.params
    }
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}