import {getAction, postAction} from "@/api/manage";
import {axios} from '@/utils/request';
import router from "@/router";

export function getTaskInfoByAxios(taskId) {
  // const currentRoute = router.currentRoute
  // return getAction('/jeecg-system/aqyh/app/task/getPage', {
  //   teamId: currentRoute?.query?.teamId || '1631264505029726210',
  //   pageNo: 1,
  //   pageSize: 10
  // }).then(res => {
  //   if (res.success) {
  //     const result = res.result.records.find(item => item.id === taskId)
  //     return Promise.resolve({...res, result: JSON.stringify(result)})
  //   } else {
  //     return Promise.resolve(res)
  //   }
  // })
  return getAction('/jeecg-system/aqyh/app/task/getTaskAllInfoById', {
    id: taskId
  }).then(res => {
    if (res.success) {
      const result = res.result
      return Promise.resolve({...res, result: JSON.stringify(result)})
    } else {
      return Promise.resolve(res)
    }
  })
}

export function submitTaskByAxios(taskInfo) {
  return postAction('/jeecg-system/aqyh/app/task/submitTask', taskInfo).then(res => {
    return Promise.resolve(res)
  })
}

export function getArticleNoRealInfo(id) {
  return getAction('/jeecg-system/aqyh/app/task/getArticleNoRealInfo', {
    id
  })
}


export function getCategoryList(articleNoId) {
  return getAction('/jeecg-system/aqyh/productArticleNumber/getDefectList', {
    articleNoId:  articleNoId
  }).then(res => {
    if (res.success) {
      return Promise.resolve({...res, result: JSON.stringify(res.result)})
    } else {
      return Promise.resolve(res)
    }
  })
}

export function uploadFile(info) {
  const data = new FormData()
  data.append('file', info.file)
  return axios({
    url: 'jeecg-system/platform/common/upload_file',
    method: "post",
    data,
    headers: {
      'Content-Type': 'multipart/form-data',  // 文件上传
    }
  }).then(res => {
    if (res.success) {
      return Promise.resolve({...res, result: res.message})
    } else {
      return Promise.resolve(res)
    }
  })
}
