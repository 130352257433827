import { render, staticRenderFns } from "./ImgUploader.vue?vue&type=template&id=713797c1&scoped=true&"
import script from "./ImgUploader.vue?vue&type=script&lang=js&"
export * from "./ImgUploader.vue?vue&type=script&lang=js&"
import style0 from "./ImgUploader.vue?vue&type=style&index=0&id=713797c1&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713797c1",
  null
  
)

export default component.exports