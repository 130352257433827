<template>
  <div>
    <div class="example">
      <van-uploader
        v-model="exampleImg"
        readonly
        :show-upload="false"
        :deletable="false"
        :preview-size="69"
      />
    </div>
  </div>
</template>

<script>
import {imgStrToList} from "@/utils/util";

export default {
  name: "ExampleImgUploader",
  props: {
    value: {
      type: [Array, String],
      default() {
        return []
      }
    },
  },
  data() {
    return {
      exampleImg: '',
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (value) {
        value = value || '{}'
        const data = typeof value === 'string' ? JSON.parse(value) : value
        this.exampleImg = imgStrToList(data.exampleImg)
      }
    }
  },
}
</script>

<style scoped lang="less">
::v-deep {
  .van-uploader__upload {
    border-radius: 2px;
  }

  .van-uploader__preview {
    margin-bottom: 16px;

    .van-image__img {
      border-radius: 2px;
    }
  }
}
</style>
