<template>
  <div class="custom-area">
    {{ textVal || "无"}}
  </div>
</template>

<script>
export default {
  name: "CustomTextarea",
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      textVal: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (value) {
        this.textVal = value
      }
    }
  },
}
</script>

<style lang="less" scoped>
.custom-area {
  //background-color: #f5f7fa;
  //color: #c0c4cc;
  //padding: 5px 12px;
  //border: 1px solid #e4e7ed;
  cursor: not-allowed;
  line-height: 1.5;
  font-size: 14px;
  color: #4B4E51;
  padding-top: 6px;
}
</style>