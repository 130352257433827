export function timeFix() {
  const time = new Date();
  const hour = time.getHours();
  return hour < 9
    ? "早上好"
    : hour <= 11
    ? "上午好"
    : hour <= 13
    ? "中午好"
    : hour < 20
    ? "下午好"
    : "晚上好";
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  let event = document.createEvent("HTMLEvents");
  event.initEvent("resize", true, true);
  event.eventType = "message";
  window.dispatchEvent(event);
}

/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
export function filterObj(obj) {
  if (!(typeof obj === "object")) {
    return;
  }

  for (var key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      (obj[key] === null || obj[key] === undefined || obj[key] === "")
    ) {
      delete obj[key];
    }
  }
  return obj;
}

/**
 * 时间格式化
 * @param value
 * @param fmt
 * @returns {*}
 */
export function formatDate(value, fmt) {
  var regPos = /^\d+(\.\d+)?$/;
  if (regPos.test(value)) {
    // 如果是数字
    let getDate = new Date(value);
    let o = {
      "M+": getDate.getMonth() + 1,
      "d+": getDate.getDate(),
      "h+": getDate.getHours(),
      "m+": getDate.getMinutes(),
      "s+": getDate.getSeconds(),
      "q+": Math.floor((getDate.getMonth() + 3) / 3),
      S: getDate.getMilliseconds(),
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (getDate.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    for (let k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  } else {
    // TODO
    value = value.trim();
    return value.substr(0, fmt.length);
  }
}

/**
 * 深度克隆对象、数组
 * @param obj 被克隆的对象
 * @return 克隆后的对象
 */
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber() {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);
  if (arguments.length === 1) {
    let [length] = arguments;
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) =>
      i > 0 ? random(0, 9) : random(1, 9)
    );
    return parseInt(nums.join(""), 10);
  } else if (arguments.length >= 2) {
    let [min, max] = arguments;
    return random(min, max);
  } else {
    return Number.NaN;
  }
}

/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(length, chats) {
  if (!length) {
    length = 1;
  }
  if (!chats) {
    chats = "0123456789qwertyuioplkjhgfdsazxcvbnm";
  }
  let str = "";
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1);
    str += chats[num];
  }
  return str;
}

/**
 * 随机生成uuid
 * @return string 生成的uuid
 */
export function randomUUID() {
  let chats = "0123456789abcdef";
  return randomString(32, chats);
}

/**
 * 下划线转驼峰
 * @param string
 * @returns {*}
 */
export function underLine2CamelCase(string) {
  return string.replace(/_([a-z])/g, function(all, letter) {
    return letter.toUpperCase();
  });
}

/**
 * 获取字符串的长度ascii长度为1 中文长度为2
 * @param str
 * @returns {number}
 */
export const getStrFullLength = (str = "") =>
  str.split("").reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0);
    if (charCode >= 0 && charCode <= 128) {
      return pre + 1;
    }
    return pre + 2;
  }, 0);

/**
 * 给定一个字符串和一个长度,将此字符串按指定长度截取
 * @param str
 * @param maxLength
 * @returns {string}
 */
export const cutStrByFullLength = (str = "", maxLength) => {
  let showLength = 0;
  return str.split("").reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0);
    if (charCode >= 0 && charCode <= 128) {
      showLength += 1;
    } else {
      showLength += 2;
    }
    if (showLength <= maxLength) {
      return pre + cur;
    }
    return pre;
  }, "");
};

/**
 * 清理空值，对象
 * @param children
 * @returns {*[]}
 */
export function filterEmpty(children = []) {
  return children.filter((c) => c.tag || (c.text && c.text.trim() !== ""));
}

export function formatTreeData(
  tree,
  labelKey = "label",
  valueKey = "value",
  childrenKey = "children"
) {
  let list = [];
  tree.reduce((prev, cur) => {
    let leaf = { ...cur, active: [] };
    delete leaf["childrenKey"];
    if (
      cur[childrenKey] &&
      Array.isArray(cur[childrenKey]) &&
      cur[childrenKey].length > 0
    ) {
      leaf.children = formatTreeData(
        cur[childrenKey],
        labelKey,
        valueKey,
        childrenKey
      );
    }
    prev.push(leaf);
    return list;
  }, list);

  return list;
}

export function formatCheckList(list) {
  // let result = []
  // Object.entries(obj).forEach(([key, value])=>{
  //   let children = Array.isArray(value) ? value : formatCheckObj(value)
  //   let checkpointType = children[0].checkpointType
  //   let checkType = children[0].checkType
  //   let customCheckpointId = checkpointType === 'CUSTOM_CHECKPOINT' ? children[0].id || children[0].customCheckpointId : ''
  //   result.push({
  //     itemName: key,
  //     active: [],
  //     children: children,
  //     checkpointType,
  //     checkType,
  //     customCheckpointId
  //   })
  // })
  // return result
  let result = list.map((i) => {
    if (i.taskInspection) {
      let form = i.taskInspection;
      if (form.taskInspectionExpands?.length > 0) {
        form.scanList = form.taskInspectionExpands.map((item) => {
          return {
            id: item.id,
            imgData: item.imgData,
            scanTime: item.scanTime,
          };
        });
      } else form.scanList = [];
      if (form.images) {
        form.photoList = form.images.split(",").map((item) => {
          return {
            content: item,
            url: item,
          };
        });
      } else {
        form.photoList = [];
      }
    }
    return i
  });
  return result;
}

export function formatCheckItemToCascader(list) {
  let result = [];
  if (!list) return;
  list.forEach((item, index) => {
    if (item?.children?.length === 1 && item.children[0].itemName === "") {
      result.push({
        itemName: item.itemName,
        children: null,
        index,
      });
    } else if (item?.children?.length >= 1 && item.children[0].id) {
      result.push({
        itemName: item.itemName,
        children: null,
        index,
      });
    } else {
      result.push({
        itemName: item.itemName,
        children: formatCheckItemToCascader(item.children),
        index,
      });
    }
  });
  return result;
}


export function getRandomId(){
  const timeStamp = new Date().getTime()
  return `frontend_${timeStamp}`
}

export function fileStrToList(fileStr){
  const fileList = fileStr ? fileStr.split(",") : []
  const result = fileList.map((item) => {
    if(item.startsWith('EX-')) {
      item = item.slice(3)
    }
    let path = item.split("/");
    return {
      url: item,
      name: decodeURI(path[path.length - 1]),
    };
  })
  return result || []
}

export function imgStrToList(imgStr){
  const imgList = imgStr ? imgStr.split(",") : []
  const result = imgList.map((item) => {
    if(item.startsWith('EX-')) {
      item = item.slice(3)
    }
    return {
      url: item,
      content: item,
    }
  })
  return result || []
}