import { Toast } from "vant";
// 两个接口域名
import { axios } from "@/utils/request";
// import {
//   axiosMobile
// } from '@/utils/requestMobile' // 移动端的接口域名，商品分类

//post
export function postAction(url, parameter) {
  return axios({
    url: url,
    method: "post",
    data: parameter,
  });
}

// //post method= {post | put}
// export function httpAction(url, parameter, method) {
//   return axios({
//     url: url,
//     method: method,
//     data: parameter,
//   });
// }

//put
export function putAction(url, parameter) {
  return axios({
    url: url,
    method: "put",
    data: parameter,
  });
}

//put
export function putActionQuery(url, parameter) {
  return axios({
    url: url,
    method: "put",
    params: parameter,
  });
}

//get
export function getAction(url, parameter) {
  return axios({
    url: url,
    method: "get",
    params: parameter,
  });
}

//deleteAction
export function deleteAction(url, parameter) {
  return axios({
    url: url,
    method: "delete",
    params: parameter,
  });
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter) {
  return axios({
    url: url,
    params: parameter,
    method: "get",
    responseType: "blob",
  });
}

/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
export function downloadFile(url, fileName, parameter) {
  return downFile(url, parameter).then((data) => {
    if (!data || data.size === 0) {
      Vue.prototype["$message"].warning("文件下载失败");
      return;
    }
    if (typeof window.navigator.msSaveBlob !== "undefined") {
      window.navigator.msSaveBlob(new Blob([data]), fileName);
    } else {
      let url = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(url); //释放掉blob对象
    }
  });
}


/**
 * 获取文件服务访问路径
 * @param avatar
 * @param subStr
 * @returns {*}
 */
export function getFileAccessHttpUrl(avatar, subStr) {
  if (!subStr) subStr = "http";
  try {
    if (avatar && avatar.startsWith(subStr)) {
      return avatar;
    } else {
      if (avatar && avatar.length > 0 && avatar.indexOf("[") == -1) {
        return window._CONFIG["staticDomainURL"] + "/" + avatar;
      }
    }
  } catch (err) {
    return;
  }
}

/**
 * 请求
 * @param {请求地址} url
 * @param {请求方式} method
 * @param {请求参数} parameter
 * @param {回调函数} successCallback
 */
export function httpAction(url, method, parameter, successCallback) {
  if (successCallback) {
    if (method === "get" || method === "put" || method === "delete") {
      axios({
        url: url,
        method: method,
        params: parameter,
      }).then((res) => {
        if (res.code === 0 || res.code === 200) {
          if (res.data) {
            successCallback(res.data);
          }
          if (res.result) {
            successCallback(res.result);
          }
        } else {
          if (res.message) {
            Toast.fail(res.message);
          } else {
            Toast.fail("系统异常，请联系管理员");
          }
        }
      });
    }
    if (method === "post") {
      axios({
        url: url,
        method: method,
        data: parameter,
      }).then((res) => {
        if (res.code === 0 || res.code === 200) {
          successCallback(res.data);
        } else {
          if (res.message) {
            Toast.fail(res.message);
          } else {
            Toast.fail("系统异常，请联系管理员");
          }
        }
      });
    }
  } else {
    if (method === "get" || method === "put" || method === "delete") {
      return axios({
        url: url,
        method: method,
        params: parameter,
      });
    }
    if (method === "post") {
      return axios({
        url: url,
        method: method,
        data: parameter,
      });
    }
  }
}

/**
 * 文件上传
 * @param {请求地址} url
 * @param {请求方式} method
 * @param {文件流} parameter
 */
export function uploadAction(url, method, parameter) {
  let formdata = new FormData();
  formdata.append("file", parameter);
  if (method === "get" || method === "put" || method === "delete") {
    return axios({
      url: url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: method,
      params: formdata,
    });
  }
  if (method === "post") {
    return axios({
      url: url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: method,
      data: formdata,
    });
  }
}
