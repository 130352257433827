<template>
  <div>
    <!--    <div class="example">-->
    <!--      <div>示例图片：</div>-->
    <!--      <van-uploader-->
    <!--        v-model="exampleImg"-->
    <!--        readonly-->
    <!--        :show-upload="false"-->
    <!--        :deletable="false"-->
    <!--        :preview-size="69"-->
    <!--      />-->
    <!--    </div>-->
    <!--    <div>拍照要求：{{limit || 0}}张</div>-->
    <div v-if="limit && limit !== '0'">拍摄照片，至少{{ limit }}张</div>
    <div v-if="!$attrs.disabled" class="vertical-center">
      <div class="image-btn camera-btn" @click="toggleCameraInput">
        <div class="icon-wrap center">
          <i class="el-icon-camera-solid"></i>
        </div>
        <div class="text">拍摄</div>
      </div>
      <div class="image-btn photo-btn" @click="togglePhotoInput">
        <div class="icon-wrap center">
          <i class="el-icon-picture"></i>
        </div>
        <div class="text">相册</div>
      </div>
    </div>
    <van-uploader
      v-model="imgList"
      class="img-uploader"
      :preview-size="69"
      :max-count="maxCount"
      :upload-icon="require('@/assets/svg/icon-camera.svg')"
      :after-read="onAfterRead"
      :accept="accept"
      :deletable="!$attrs.disabled"
      :show-upload="!$attrs.disabled"
      :preview-image="previewImage"
      :multiple="multiple"
      @delete="onChange"
    >
      <input v-show="false" ref="photoRef" type="file" accept="image/*" @change="handleSelectPhoto"/>
      <input v-show="false" ref="cameraRef" type="file" capture="capture" @change="handleSelectPhoto"/>
    </van-uploader>
  </div>
</template>

<script>
import {isNative} from "@/config";
import {imgStrToList} from "@/utils/util";
import {uploadFile} from "@/utils/getDataByAxios";
import emitter from 'element-ui/src/mixins/emitter';

export default {
  name: "ImgUploader",
  mixins: [emitter],
  props: {
    value: {
      type: [Array, String],
      default() {
        return []
      }
    },
    maxCount: {
      type: Number,
      default: 999,
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    previewImage: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      imgList: [],
      exampleImg: '',
      limit: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (value) {
        value = value || '{}'
        const data = typeof value === 'string' ? JSON.parse(value) : value
        this.exampleImg = imgStrToList(data.exampleImg)
        this.limit = data.limit || null
        this.imgList = imgStrToList(data.imgList)
      }
    }
  },
  methods: {
    togglePhotoInput() {
      if (this.imgList.length >= 30) {
        this.$toast.fail('上传图片不能超过30张')
        return
      }
      this.$refs.photoRef.click()
    },
    toggleCameraInput() {
      if (this.imgList.length >= 30) {
        this.$toast.fail('上传图片不能超过30张')
        return
      }
      this.$refs.cameraRef.click()
    },
    handleSelectPhoto(e) {
      let fileList = []
      const filesArr = Array.from(e.target.files)
      filesArr.forEach(item => {
        fileList.push(item)
      })

      if (this.imgList.length + fileList.length > 30) {
        const num =  30 - this.imgList.length
        this.Toast(`总共最多上传30张图片,自动为您上传前${num}张图片...`)
        fileList = fileList.slice(0, num)
      } else if (filesArr?.length > 9) {
        this.Toast('单次最多上传9张图片,自动为您上传前9张图片...')
        fileList = fileList.slice(0, 9)
      }
      fileList.forEach(async file => {
        const content = await this.getBase64(file)
        const info = {content, file}
        this.imgList.push(info)
        this.onAfterRead(info)
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    onChange() {
      if (this.imgList.find(item => !item.url)) {
        return
      }
      const val = {
        limit: this.limit,
        exampleImg: this.exampleImg.map(item => item.url).join(','),
        imgList: this.imgList.map(item => item.url).join(',')
      }
      this.$emit('input', JSON.stringify(val))
      if (this.imgList?.length === parseInt(this.limit)) {
        this.dispatch("ElFormItem", "el.form.change", [val])
      }
    },
    async onAfterRead(info) {
      info.status = 'uploading'
      info.message = "上传中..."
      let res = {}
      try {
        if (isNative) {
          res = await window.nativeApp.submitImg(info.content)
        } else {
          res = await uploadFile(info)
        }
        if (res.success) {
          info.status = 'success'
          info.url = res.result
          info.content = res.result
        } else {
          this.onUploadFail(info, res)
        }
      } catch (e) {
        this.onUploadFail(info)
      }
      this.onChange()
    },
    onUploadFail(info, res) {
      let index = this.imgList.findIndex(item => item.content === info.content)
      if (index > -1) {
        this.imgList.splice(index, 1)
      }
      this.Toast.fail(res?.message || "上传失败")
    },
  },
}
</script>

<style scoped lang="less">
::v-deep {
  .van-uploader__upload {
    border-radius: 2px;
  }

  .van-uploader__preview {
    margin-bottom: 16px;

    .van-image__img {
      border-radius: 2px;
    }
  }

  .image-input {
    visibility: hidden;
  }

  .image-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    .icon-wrap {
      width: 45px;
      height: 45px;
      background: #F9F9F9;

      i {
        font-size: 24px;
      }
    }

    .text {
      margin-top: 6px;
      font-size: 12px;
      color: #4B4E51;
    }
  }
}
</style>
