export const TASK_ALL = ''
export const TASK_PROCESSING = 'PROCESSING'
export const TASK_FINISHED = 'COMPLETED'
export const WAIT_INSPECT = 'WAIT_INSPECT'
export const taskStatus = {WAIT_INSPECT,TASK_ALL, TASK_PROCESSING, TASK_FINISHED}
export const CHECK_TYPE_BAR = 'barCode'
export const CHECK_TYPE_NORMAL = 'normal'
export const CHECK_TYPE_CUSTOM = 'custom'
export const CHECKPOINT_CUSTOM = 'CUSTOM_CHECKPOINT'
export const CHECKPOINT_ATTRIBUTE = 'ATTRIBUTE_CHECKPOINT'
export const CHECK_TYPE_S_BAR = 'SINGLE_BARCODE'
export const CHECK_TYPE_S_ITEM = 'ITEM_CHECKPOINT'
export const CHECK_TYPE_P_BAR = 'PRODUCT_BARCODE'
export const CHECK_TYPE_P_ITEM = 'PRODUCT_INSPECTION'
export const NOT_FORMED = 'NOT_FORMED'
export const SUBMITTED = 'SUBMITTED'
export const SUPPLIER_CONFIRMED = 'SUPPLIER_CONFIRMED'
export const CHECK_JUDGE_DICT = {
  'QUALIFIED': '合格',
  'FAILED': '不合格',
  'PENDING': '待定',
  'UNCHECKED': '未检',
}
export const INSPECT_DICT = {
  'PASSED': '通过',
  'REJECT': '拒收',
  'UNTESTED': '未验',
  'PENDING': '待定',
}
export const TASK_INSPECT_DICT = {
  'PASSED': '通过',
  'NOT_PASS': '未通过',
  'PENDING': '待定',
}
export const TASK_STATUS_DICT = {
  "PROCESSING": "进行中",
  "CANCELLED": "已取消",
  "COMPLETED": "已完成"
}


export const SAMPLING_LEVEL_DICT = {
  'LEVEL_G_ONE': "Ⅰ",
  'LEVEL_G_TWO': "Ⅱ",
  'LEVEL_G_THREE': "Ⅲ",
  'LEVEL_S_ONE': "S-1",
  'LEVEL_S_TWO': "S-2",
  'LEVEL_S_THREE': "S-3",
  'LEVEL_S_FOUR': "S-4"
}

export const ARTICLE_NO_WAITING_START = '01'
export const ARTICLE_NO_PROCESSING = '02'
export const ARTICLE_NO_WAITING_CERTAIN = '03'
export const ARTICLE_NO_COMPLETED = '04'
export const articleNoStatusObj = {
  ARTICLE_NO_WAITING_START,
  ARTICLE_NO_PROCESSING,
  ARTICLE_NO_WAITING_CERTAIN,
  ARTICLE_NO_COMPLETED
}
export const ARTICLE_NO_STATUS_DICT = {
  // [ARTICLE_NO_WAITING_START]: '点数抽样',
  [ARTICLE_NO_WAITING_START]: '待验货',
  [ARTICLE_NO_PROCESSING]: '验货中',
  [ARTICLE_NO_WAITING_CERTAIN]: '待确认',
  [ARTICLE_NO_COMPLETED]: '已完成'
}

export const ARTICLE_CONCLUSION_AGREE = '1'
export const ARTICLE_CONCLUSION_DISAGREE = '2'
export const ARTICLE_CONCLUSION_WAIT = '3'
export const ARTICLE_CONCLUSION_NOT = '4'
export const ARTICLE_CONCLUSION_DICT  = {
  [ARTICLE_CONCLUSION_AGREE]: '通过',
  [ARTICLE_CONCLUSION_DISAGREE]: '拒收',
  [ARTICLE_CONCLUSION_WAIT]: '待定',
  [ARTICLE_CONCLUSION_NOT]: '未验货',
}

export const SUPPLIER_CONCLUSION_AGREE = 'Y'
export const SUPPLIER_CONCLUSION_DISAGREE = 'N'
export const SUPPLIER_CONCLUSION_DICT  = {
  [SUPPLIER_CONCLUSION_AGREE]: '同意',
  [SUPPLIER_CONCLUSION_DISAGREE]: '不同意',
}
export const ALL_ARTICLE_CONCLUSION_DICT  = {
  ...ARTICLE_CONCLUSION_DICT,
  ...SUPPLIER_CONCLUSION_DICT,
}

export const ROLE_DICT = {
  "SUPPLIER": "供应商",
  "INSPECTOR": "验货员"
}


export const ORDER_WAITING_START = '01'
export const ORDER_PROCESSING = '02'
export const ORDER_WAITING_CERTAIN = '03'
export const ORDER_COMPLETED = '04'
export const ORDER_STATUS_DICT = {
  [ORDER_WAITING_START]: '待验货',
  [ORDER_PROCESSING]: '验货中',
  [ORDER_WAITING_CERTAIN]: '待确认',
  [ORDER_COMPLETED]: '已完成'
}

export const TASK_STATUS_IN_DISPATCH = '04'
export const TASK_STATUS_WAIT_INSPECT = '05'
export const TASK_STATUS_INSPECTING = '06'
export const TASK_STATUS_COMPLETED = '07'
export const TASK_STATUS_APPLY_CANCEL = '09'
export const taskStatusObj = {
  TASK_STATUS_IN_DISPATCH,
  TASK_STATUS_WAIT_INSPECT,
  TASK_STATUS_INSPECTING,
  TASK_STATUS_COMPLETED,
  TASK_STATUS_APPLY_CANCEL,
}
