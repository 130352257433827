<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {Toast} from 'vant'
import {getAction} from '@/api/manage';
import {isNative} from "@/config";

export default {
  data() {
    return {};
  },
  created() {
    this.checkToken()
    window.backOrderList = this.backOrderList
    // this.getWxConfig()
  },
  methods: {
    async getWxConfig() {
      if (this.getQueryStringValue('token')) {
        const res = await getAction('/jeecg-system/common/dxcommon/weixin/getconfig', {
          url: location.href.split('#')[0]
        })
        if (res.success) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: res.result.appId, // 必填，公众号的唯一标识
            timestamp: res.result.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.result.nonceStr, // 必填，生成签名的随机串
            signature: res.result.signature, // 必填，签名
            jsApiList: ['openLocation', 'scanQRCode'] // 必填，需要使用的 JS 接口列表
          });
        } else {
          this.Toast.fail('wx.config失败', res.message)
        }
      }
    },
    // 检查地址栏是否有token
    checkToken() {
      if (this.getQueryStringValue('token')) {
        this.$store.state.token = this.getQueryStringValue('token')
        console.log('this.$store.state.token:', this.$store.state.token)
      }
    },
    getQueryStringValue(name) {
      // const search = location.search
      let search = window.location.hash.split("?")[1]
      if (!isNative) {
        search = '?' + search
      }
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
      var regex = new RegExp('[&\\?]' + name + '=([^&#]*)')
      const results = regex.exec(search)
      return results === null ? '' : results[1].replace(/\+/g, ' ')
    },
    // 提供给app调用的返回操作
    backOrderList() {
      if(this.$route.path === '/check' && (this.$route.query.disabled === '1')){  // 特殊的【验货中】页面，点击【点数抽样】页面的【查看验货报告】按钮，即进入到【查看检验清单】页面（也就是【验货中】的预览页面）
        this.$router.back()
      } else if(this.$route.path === '/countSample' && (this.$route.query.from === 'check')){  // 点击【验货中】页面的【点数抽样】按钮进入到【点数抽样】页面，则返回上一级时，返回到【验货中】
        this.$router.back()
      }else if (this.$route.path === '/check' || this.$route.path === '/countSample') {
        const query = this.$route.query
        delete query['disabled']
        this.$router.replace({
          path: '/orderList',
          query: {
            ...query
          },
        })
      } else if (this.$route.path === '/orderList' && isNative) {
        window.nativeApp.finish()
      } else if (window.history.length > 1) {
        this.$router.back()
      } else {
        window.nativeApp.finish()
      }
    },
  }
};
</script>
<style>
#app {
    height: 100%;
}

html, body {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
