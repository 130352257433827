<template>
  <el-radio-group v-model="radioValue" :disabled="$attrs.disabled" @change="onChange">
    <el-radio
      v-for="item in $attrs.dictData"
      :key="item.value"
      :label="`${item.value}`"
      @click.native.prevent="handleRadioClick(item.value)"
    >
      {{ item.label }}
    </el-radio>
  </el-radio-group>
</template>

<script>

import emitter from "element-ui/src/mixins/emitter";

export default {
  name: "CustomRadio",
  mixins: [emitter],
  props: {
    value: {
      type: [Array, String],
      default: ''
    },
  },
  data() {
    return {
      radioValue: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (value) {
        this.radioValue = value
      }
    }
  },
  methods: {
    handleRadioClick(e) {
      if (this.$attrs.disabled) return
      if (this.radioValue === e) {
        this.radioValue = ''
      } else {
        this.radioValue = e
      }
      this.onChange(this.radioValue)
    },
    onChange(val) {
      this.$emit('input', val)
      if (this.radioValue) {
        this.dispatch("ElFormItem", "el.form.change", [val])
      }
    }
  }
}
</script>

<style scoped lang="less">
::v-deep  .el-radio__inner{
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #dcdfe6 !important;
}
</style>