import Vue from "vue";
import Vuex from "vuex";
import taskInfoJson from "@/mock/taskInfo.json";
import taskDateList from "@/mock/taskDateList.json";
import {isNative} from "@/config";
import {Toast} from "vant";
import {getTaskInfoByAxios, submitTaskByAxios} from "@/utils/getDataByAxios";
import {getTaskStatus} from "@/utils/taskData";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    // token: '',
    taskDetail: {},
    orderItemDetail: {},
    checkItemList: [],
    taskId: '',
    taskInfo: {},
  },
  mutations: {
    SET_TASK_ID: (state, payload) => {
      state.taskId = payload;
    },
    SET_TASK_DETAIL: (state, payload) => {
      state.taskDetail = payload;
    },
    SET_TASK_INFO: (state, payload) => {
      state.taskInfo = payload;
    },
    SET_DEFECT_TYPE_LIST: (state, payload) => {
      state.taskDateList = payload;
    },
  },
  actions: {
    async getTaskInfo({commit, state}, payload) {
      let res = {}
      if (isNative) {
        res = await window.nativeApp.getTaskData(state.taskId);
      } else {
        res = await getTaskInfoByAxios(state.taskId)
      }
      if (res.success  || res.code === -5) {
        let taskInfo = {}
        if(res.result ) {
          if(typeof res.result === 'string') {
            taskInfo = res.result ? JSON.parse(res.result) : {}
          } else taskInfo = res.result
        }
        commit('SET_TASK_INFO', taskInfo)
        return res
      } else {
        Toast.fail(res.message)
        return res
      }
    },
    async saveTaskInfo({commit, state}, taskInfo) {
      taskInfo.updateFlag = 'Y'
      taskInfo.status = getTaskStatus(taskInfo)
      let res = {}
      if (isNative) {
        try {
          res = await window.nativeApp.updateTaskData(state.taskId, taskInfo);
        } catch (e) {
          console.log('保存任务数据失败：')
          console.error(e)
        }
      } else {
        res = await submitTaskByAxios(taskInfo)
      }
      if (res.success) {
        return res
      } else {
        Toast.fail(res.message)
        return res
      }
    }
  },
  modules: {},
  getters: {
    orderItemDetail: state => state.orderItemDetail,
    taskDetail: state => state.taskDetail,
    checkItemList: state => state.checkItemList,
    taskInfo: state => state.taskInfo || {},
  }
});
