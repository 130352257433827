<template>
  <div>
    <div class="example">
<!--      <div>辅助文件：</div>-->
      <div
        v-for="(file, index) in exampleFile"
        :key="index"
        class="space-between file"
      >
        <div class="name">{{ file.name }}</div>
        <a class="btn" @click="handleView(file.url)">查看</a>
      </div>
    </div>
    <template v-if="fileList && fileList.length">
      <div v-for="(item, index) in fileList" :key="index" class="item space-between">
        <div class="file-name">
          <img v-if="item.status === 'loading'" src="~@/assets/svg/icon-upload-loading.svg" alt="" class="loading-img">
          <img v-else-if="item.status === 'success'" src="~@/assets/svg/icon-upload-check.svg" alt="">
          <div style="width: 16px;"></div>
          <div v-if="item.status === 'uploading'">上传中...</div>
          <div v-else class="name">{{ item.name }}</div>
        </div>
<!--        <div v-if="!$attrs.disabled" class="del-btn center" @click="handleFileDel(index)">删除</div>-->
      </div>
    </template>
<!--    <van-uploader-->
<!--      v-model="fileList"-->
<!--      class="fileUploader"-->
<!--      :max-count="maxCount"-->
<!--      :after-read="onAfterRead"-->
<!--      accept=".xls,.xlsx,.ppt,.pptx,.doc,.docx,.pdf"-->
<!--      :preview-image="false"-->
<!--      :multiple="multiple"-->
<!--    >-->
<!--      <van-button v-if="!$attrs.disabled" color="#055C9D" plain class="upload-btn">上传文件</van-button>-->
<!--      <div v-else></div>-->
<!--    </van-uploader>-->
  </div>
</template>

<script>
import {isNative} from "@/config";
import {fileStrToList, imgStrToList} from "@/utils/util";
import {uploadFile} from "@/utils/getDataByAxios";
import {Base64} from "@/utils/base64";

export default {
  name: "FileUploader",
  props: {
    value: {
      type: [Array, String],
      default() {
        return []
      }
    },
    maxCount: {
      type: Number,
      default: 20,
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      fileList: [],
      exampleFile: [],
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (value) {
        value = value || '{}'
        const data = typeof value === 'string' ? JSON.parse(value) || {} : value
        this.exampleFile = fileStrToList(data.exampleFile)
        this.fileList = fileStrToList(data.fileList)
      }
    }
  },
  methods: {
    onChange() {
      const val = {
        exampleFile: this.exampleFile.map(item => item.url).join(','),
        fileList: this.fileList.map(item => item.url).join(','),
      }
      this.$emit('input', JSON.stringify(val))
    },
    handleFileDel(index) {
      this.fileList.splice(index, 1)
      this.onChange()
    },
    async handleDownload(url) {
      if (isNative) {
        try {
          const res = await window.nativeApp.fileDownload(url);
          console.log('下载操作结果为：', res)
        } catch (e) {
          console.log('下载操作错误：')
          console.error(e)
        }
      }
    },
    handleView(fileUrl){
      const base64Url = Base64.encode(fileUrl)
      const url = `${process.env.VUE_APP_KK_FILE_URL}?url=${encodeURIComponent(base64Url)}`
      window.location.href = url
    },
    async onAfterRead(info) {
      info.status = 'uploading'
      let res = {}
      try {
        if (isNative) {
          res = await window.nativeApp.submitFile(info.file.name, info.content)
        } else {
          res = await uploadFile(info)
        }
        if (res.success) {
          info.status = 'success'
          info.url = res.result
          info.content = res.result
        } else {
          this.onUploadFail(info, res)
        }
      } catch (e) {
        this.onUploadFail(info)
      }
      this.onChange()
    },
    onUploadFail(info, res) {
      let index = this.fileList.findIndex(item => item.content === info.content)
      if (index > -1) {
        this.fileList.splice(index, 1)
      }
      this.Toast.fail(res?.message || "上传失败")
    },
  },
}
</script>

<style scoped lang="less">
.example {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  letter-spacing: 0;
  white-space: pre-line;
  max-width: calc(100% - 20px);
  margin-bottom: 10px;
  margin-top: 5px;

  .file {
    line-height: 22px;

    .name {
      width: calc(100% - 60px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .btn {
      width: 40px;
      color: #055c9d;
      font-size: 12px;
    }
  }

}

::v-deep {
  .item {
    width: 100%;
    margin-bottom: 8px;

    .file-name {
      //width: calc(100% - 72px - 17px);
      width: calc(100% - 17px);
      height: 32px;
      background: rgba(255, 255, 255, 0.90);
      border: 1px solid #DCDCDC;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin: 0 8px;

        &.loading-img {
          -moz-animation: loadingRotate 1s linear infinite;
          -webkit-animation: loadingRotate 1s linear infinite;
          -o-animation: loadingRotate 1s linear infinite;
          animation: loadingRotate 1s linear infinite;
        }
      }

      .name {
        display: inline-block;
        width: calc(100% - 16px - 16px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .del-btn {
      width: 60px;
      height: 32px;
      background: rgba(255, 255, 255, 0.90);
      border: 1px solid #DCDCDC;
      border-radius: 3px;
      margin-left: 12px;
      flex-shrink: 0;
    }
  }

  .upload-btn {
    height: 36px;
  }
}
</style>
