import Vue from "vue";
import Vant, {Toast, Dialog, ImagePreview} from "vant";
import ElementUI from 'element-ui'
import Avue from '@smallwei/avue'
// import AvueUeditor from 'avue-plugin-ueditor'
import 'element-ui/lib/theme-chalk/index.css'
import {VueAxios, axios} from "@/utils/request";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import imgUploader from "@/components/Common/ImgUploader";
Vue.use(imgUploader)
import fileUploader from "@/components/Common/FileUploader";
Vue.use(fileUploader)
import barcodeScanner from "@/components/Common/BarcodeScanner";
Vue.use(barcodeScanner)
import customRadio from "@/components/Common/CustomRadio";
Vue.use(customRadio)
import customTextarea from "@/components/Common/CustomTextarea";
Vue.use(customTextarea)
import exampleImgUploader from "@/components/Common/ExampleImgUploader";
Vue.use(exampleImgUploader)

import "vant/lib/index.css";
import "lib-flexible/flexible";
import "@/utils/filter"; // base filter
import "@/styles/global.less"
import "@/styles/element-theme/theme/index.css";
import "@/styles/element-ui.less"
import "@/styles/vant-ui.less"
import '@smallwei/avue/lib/index.css'
import Vconsole from 'vconsole'

if(process.env.VUE_APP_DEBUG_CONSOLE === 'true') {
  const vConsole = new Vconsole()
}

router.afterEach((to, from, next) => {
  if (to.meta?.title) {
    document.title = to.meta.title;
  }
});

Vue.config.productionTip = false;
Vue.prototype.Toast = Toast
Vue.prototype.Dialog = Dialog
Vue.prototype.ImagePreview = ImagePreview
Vue.use(axios);
Vue.use(VueAxios);
Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(Avue)
// Vue.use(AvueUeditor)
console.log(process.env.VUE_APP_BASE_API);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
