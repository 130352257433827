<template>
  <div class="scan-box">
    <div v-show="isShowTip" class="check-tip">
      扫描条形码，至少有{{ minCount }}个记录
    </div>
    <div class="scan-btn center" @click="handleScan">
      <img src="~@/assets/svg/icon-scanning.svg" alt=""/>
    </div>
    <div v-show="barcodeList && barcodeList.length" class="record-tip">
      扫码记录:
    </div>
    <div v-for="(item, index) in barcodeList" :key="index" class="value-box">
      <div class="vertical-center code">
        <span>{{ item && item.code }}</span>
        <img v-if="!$attrs.disabled" src="@/assets/svg/delete.svg" alt="" @click="handleDel(index)">
      </div>
      <div v-if="item.createTime" class="time">
        {{ item && item.createTime }}
      </div>
    </div>
  </div>
</template>

<script>
import {isNative} from "@/config";
import emitter from 'element-ui/src/mixins/emitter';
import moment from "moment";

export default {
  name: "BarcodeScanner",
  mixins: [emitter],
  props: {
    value: {
      type: [Array, String],
      default() {
        return []
      }
    },
  },
  data() {
    return {
      barcodeList: [],
      minCount: null
    }
  },
  computed: {
    isShowTip() {
      return this.minCount && this.minCount != 0
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (value) {
        value = value || '{}'
        const data = typeof value === 'string' && value ? JSON.parse(value) || {} : value
        this.minCount = data.minCount
        const barcodeList = data.barcodeList || []
        this.barcodeList = barcodeList.map(item => {
          // 老数据修正
          if (typeof item === 'string') {
            return {
              code: item,
              createTime: ''
            }
          } else if (typeof item === 'object') {
            return item
          }
        })
      }
    }
  },
  methods: {
    handleDel(index) {
      this.barcodeList.splice(index, 1)
      this.onChange()
    },
    onChange() {
      const val = {
        minCount: this.minCount,
        barcodeList: this.barcodeList
      }
      this.$emit('input', JSON.stringify(val))
    },
    async handleScan() {
      if (this.$attrs.disabled) {
        return
      }
      let res = {}
      if (isNative) {
        try {
          res = await window.nativeApp.codeScan();
          console.log('扫描结果为：', res)
        } catch (e) {
          console.log('扫描条形码错误：')
          console.error(e)
        }
      } else res = 'testCode'
      if (!Array.isArray(this.barcodeList)) {
        this.barcodeList = []
      }
      if (res) {
        this.barcodeList.unshift({code: res, createTime: moment().format('YYYY-MM-DD HH:mm:ss')})
      }
      this.onChange()
      if (this.barcodeList?.length >= this.minCount) {
        this.dispatch("ElFormItem", "el.form.change", [this.barcodeList.join(',')])
      }
    },
  }
}
</script>

<style lang="less" scoped>
.scan-box {
  font-size: 14px;
  color: #4B4E51;

  .scan-btn {
    width: 70px;
    height: 70px;
    background: #f9f9f9;
    border-radius: 2px;

    img {
      width: 28px;
      height: 28px;
    }
  }

  .record-tip {
    margin-bottom: -15px;
  }

  .value-box {
    .code {
      height: 14px;
      font-size: 14px;
      color: #4B4E51;
      letter-spacing: 0;
      line-height: 14px;
      margin-top: 16px;

      img {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
    }

    .time {
      font-size: 12px;
      color: #797C80;
      letter-spacing: 0;
      line-height: 12px;
      margin-top: 8px;
    }

  }
}
</style>