import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  // mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({y: 0}),
  routes: [
    {
      path: "/user/unionLogin",
      component: () => import("@/views/unionLogin"),
      name: "unionLogin",
    },
    {
      path: "/",
      component: () => import("@/views/index"),
      name: "index",
    },
    {
      path: "/taskDetail",
      component: () => import("@/views/taskDetail.vue"),
      name: "taskDetail",
    },
    {
      path: "/checkDetail",
      component: () => import("@/views/checkDetail.vue"),
      name: "checkDetail",
    },
    {
      path: "/taskCheck",
      component: () => import("@/views/taskCheck.vue"),
      name: "taskCheck",
    },
    {
      path: "/feedback",
      component: () => import("@/views/common/feedback"),
      name: "feedback",
    },
    {
      path: "/downLoad",
      component: () => import("@/views/common/downLoad"),
      name: "downLoad",
    },
    {
      path: "/articleDetali",
      component: () => import("@/views/common/articleDetali"),
      name: "articleDetali",
    },
    // 供应商
    {
      path: "/GongHuoTaskDetail",
      component: () => import("@/views/GongHuo/GongHuoTaskDetail"),
      name: "GongHuoTaskDetail"
    },
    {
      path: "/GongHuoCheckDetail",
      component: () => import("@/views/GongHuo/GonghuoCheckDetail"),
      name: "GongHuoCheckDetail"
    },
    {
      path: "/taskInfo",
      component: () => import("@/views/V2/taskInfo"),
      name: "taskInfo"
    },
    {
      path: "/orderList",
      component: () => import("@/views/V2/orderList"),
      name: "orderList"
    },
    {
      path: "/countSample",
      component: () => import("@/views/V2/countSample"),
      name: "countSample",
      meta: {title: '点数抽样'}
    },
    {
      path: "/check",
      component: () => import("@/views/V2/check"),
      name: "check",
      meta: {title: '验货中'}
    },
    {
      path: "/result",
      component: () => import("@/views/V2/result"),
      name: "result",
      meta: {title: '验货报告'}
    },
    {
      path: "/404",
      component: () => import("@/views/exception/404"),
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
});
