import {taskStatusObj, ARTICLE_NO_STATUS_Obj, articleNoStatusObj, ARTICLE_NO_WAITING_START} from "@/constants";

export function getDocumentTitle(taskInfo) {
  const num = getArticleNoLength(taskInfo)
  return `${taskInfo.supplierName || ''}-${taskInfo.inspectionType_dictText || ''}-${num}款`
}

export function getArticleNoLength(taskInfo) {
  const orderList = taskInfo.orderList || []
  const articleNoIdList = []
  orderList.forEach(order => {
    order.articleNoList.forEach(articleNo => {
      // if (!articleNoIdList.includes(articleNo.articleNo)) {
      //   articleNoIdList.push(articleNo.articleNo)
      // }
      articleNoIdList.push(articleNo.articleNo)
    })
  })
  return articleNoIdList.length
}


export function getTaskStatus(taskInfo) {
  if ([taskStatusObj.TASK_STATUS_IN_DISPATCH, taskStatusObj.TASK_STATUS_COMPLETED, taskStatusObj.TASK_STATUS_APPLY_CANCEL].includes(taskInfo.status)) {
    return taskInfo.status
  }
  const orderList = taskInfo.orderList || []
  const orderStatusList = []
  orderList.forEach(order => {
    const articleNoList = order.articleNoList || []
    const articleNoStatusList = articleNoList.map(item => item.status)
    const statusSetList = Array.from(new Set(articleNoStatusList))
    if (statusSetList.length === 1) {
      if (statusSetList[0] === articleNoStatusObj.ARTICLE_NO_WAITING_START) {
        orderStatusList.push('waitingStart')
        return
      } else if (statusSetList[0] === articleNoStatusObj.ARTICLE_NO_COMPLETED
        || statusSetList[0] === articleNoStatusObj.ARTICLE_NO_WAITING_CERTAIN ) {
        // 货号全部是已完成或全部是待确认---订单为已完成
        orderStatusList.push('complete')
        return
      }
    } else if(statusSetList.length === 2) {
      // 货号只有已完成+待确认两种状态---订单为已完成
      const isHalfWaitingCertain = statusSetList.includes(articleNoStatusObj.ARTICLE_NO_WAITING_CERTAIN)
      const isHalfCompleted = statusSetList.includes(articleNoStatusObj.ARTICLE_NO_COMPLETED)
      if( isHalfWaitingCertain && isHalfCompleted) {
        orderStatusList.push('complete')
        return
      }
    }
    orderStatusList.push('processing')
  })
  const orderStatusSetList = Array.from(new Set(orderList))
  if (orderStatusSetList.length === 1 && orderStatusSetList[0] === 'waitingStart') {
    return taskStatusObj.TASK_STATUS_WAIT_INSPECT
  } else if (orderStatusSetList.length === 1 && orderStatusSetList[0] === 'complete') {
    return taskStatusObj.TASK_STATUS_COMPLETED
  }
  return taskStatusObj.TASK_STATUS_INSPECTING
}